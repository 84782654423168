import React from 'react'

import styles from './style.module.scss'

export const View = () => (
	<div className={styles.wrapper}>
		<div className={styles.content}>
			<p>
				* Услуги физическим лицам оказывает Публичное акционерное общество РОСБАНК (Москва, ул. Маши
				Порываевой, д.34, Генеральная лицензия Банка России № 2272 от 28 января 2015 г., ИНН
				7730060164) путем заключения кредитного договора на следующих условиях:
				<br />
				<br />
				Ставка % — от 4,7% до 12.,2% годовых, определяется индивидуально исходя из индивидуальных
				условий кредитного договора. Первоначальный взнос - от 20% от цены по договору приобретения
				прав на жилое помещение. Сумма кредита от 300 000 рублей до 30 000 000 рублей, Срок кредита
				от 3 до 35 лет. Дополнительно оформляется пакет страховых услуг.
			</p>
			<p>
				Оформление заявки на получение кредита осуществляется через участника проекта инновационного
				центра &quot;Сколково&quot; ООО &quot;Кредит.Клаб&quot; (Свердловская область, г.
				Екатеринбург, ул. Малышева, стр. 51, оф. 26/14. Бизнес-центр &quot;Высоцкий&quot; ОГРН
				1196658084743 ИНН 6678105594 официальный сайт{' '}
				<a href='https://credit.club/'>https://credit.club/</a>.
			</p>
			<p>
				Окончательные условия по кредиту зависят от финансового положения заемщика, возраста,
				семейного положения, финансовой дисциплины и других обстоятельств. Окончательное решение по
				кредиту принимается кредитной организацией на основании предоставленных оригиналов
				документов в соответствии с кредитной политикой кредитной организации.
			</p>
			<p>
				Указанные кредитные продукты Банков не относится к инвестициям, предусмотренным федеральным
				законом от 02.08.2019 года № 259-ФЗ &quot;О привлечении инвестиций с использованием
				инвестиционных платформ и о внесении изменений в отдельные законодательные акты Российской
				Федерации&quot;.
			</p>
		</div>
	</div>
)
